import React, { useState, useEffect, Fragment } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import StepTitle from '../components/common/steptitle'
import ResultatenTableHeader from '../components/resultaten/tableheader'
import ResultatenTableBody from '../components/resultaten/tablebody'
import ResultatenFilters from '../components/resultaten/filters'
import CombiTable from '../components/resultaten/combis'
import Comment from '../components/common/comment'
import Actions from '../components/common/actions'
import CombinedResultModal from '../components/combineer/combinedResultModal'
import DelenEigenWagen from '../components/resultaten/delenEigenWagen'
import DeelEigenWagenModal from '../components/eigenwagendelen/deelEigenWagenModal.js'
import DeelEigenWagenTable from '../components/eigenwagendelen/deelEigenWagenTable.js'
import ResultsPdf from '../components/combineer/pdf'
import DoubleScrollbar from 'react-double-scrollbar'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import Collapse from '@material-ui/core/Collapse'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Snackbar from '@material-ui/core/Snackbar'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
// import AddIcon from '@material-ui/icons/Add'
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { strapiUrl } from '../service'
import axios from 'axios'
import { LinearProgress } from '@material-ui/core'
import { paths } from '../extras/paths.js'

const useStyles = makeStyles((theme) => ({
  totalKm: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filters: {
    padding: '0 15px',
  },
  information: {
    display: 'flex',
  },
  info2: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    marginLeft: 10,
    '& svg': {
      fill: theme.palette.secondary.main,
    },
    '& p': {
      marginLeft: 4,
      borderBottom: '1px dashed #ccc',
    },
    '& p:hover': {
      fill: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
  tableContainer: {
    width: (props) => (props.smallScreen ? '100vw' : 'inherit'),
    marginLeft: (props) => (props.smallScreen ? -15 : 'inherit'),
  },
  view: {
    margin: 'auto',
    width: '100%',
  },
  wrapper: {
    position: 'relative',
    overflow: 'auto',
    '&>div>div:nth-child(1)': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
  },
  sticky: {
    position: 'sticky',
  },
  alternativeText: {
    position: 'sticky',
    left: 0,
    padding: 16,
    borderBottom: '1px solid lightgrey',
  },
  combiButton: {
    color: '#fff',
  },
  pdfButton: {
    color: '#fff',
  },
}))

const sortFunctionPdf = (a, b) => a.jaarPrijsMotiefs - b.jaarPrijsMotiefs

const generatePdfDocument =
  (language, labels, results, alternatives, selectedMotiefs, combis, resultOwnerCar, selectedAboForComp, shareOwnCar) =>
  async () => {
    const blob = await pdf(
      <ResultsPdf
        language={language}
        labels={labels.pdf}
        results={results}
        alternatives={alternatives}
        selectedMotiefs={selectedMotiefs}
        combis={combis}
        resultOwnerCar={resultOwnerCar}
        selectedAboForComp={selectedAboForComp}
        shareOwnCar={shareOwnCar}
      />
    ).toBlob()
    saveAs(blob, `${labels.pdf.document_naam[language]}.pdf`)
  }

const fetchFormulier = async (id) => {
  // Check if uuid is defined
  if (!id) {
    return null
  }

  try {
    const response = await axios.get(`${strapiUrl}/formuliers/${id}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error)
    return null
  }
}

const Resultaten = ({
  moduleIdx,
  labels,
  modules,
  language,
  understood,
  results,
  selectedMotiefs,
  includeOwnCar,
  abos,
  autos,
  selectedOwnerCars,
  selectedAboForComp,
  selectedCarForComp,
  regios,
  combis,
  tempCombi,
  shareOwnCar,
  totalTime,
  handleSelectAboForComp,
  handleSelectCarForComp,
  handleSelectAllAboForComp,
  handleDeselectAllForComp,
  handleUpdateMotief,
  handleCalculate,
  handleSetUnderstood,
  handleSaveCombi,
  handleUpdateCombi,
  handleSetTemporaryCombi,
  handleUpdateTemporaryCombi,
  handleDeleteCombi,
  handleUpdateShareOwnCar,
}) => {
  const { id } = useParams()

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const [realSelectedMotiefs, setRealSelectedMotiefs] = useState(selectedMotiefs)

  const classes = useStyles({ nbrMotiefs: realSelectedMotiefs.length, smallScreen })
  let navigate = useNavigate()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchFormulier(id).then((data) => {
      if (data) {
        setRealResults(data.data.attributes.resultaat)

        const setSessionStorage = async () => {
          const sessionData = data.data.attributes.sessionStorage
          for (var key in sessionData) {
            if (sessionData.hasOwnProperty(key)) {
              sessionStorage.setItem(key, sessionData[key])
              if (key === 'selectedMotiefs') setRealSelectedMotiefs(JSON.parse(sessionData[key]))
            }
          }
        }

        setSessionStorage().then(() => {
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    })
  }, [id])

  const [realResults, setRealResults] = useState(results)

  const resultOwnerCar = realResults.find(({ carId }) => carId != null)

  const [priceView, setPriceView] = useState('y')
  const [showAboIncluded, setShowAboIncluded] = useState(true)
  const [showCheapest, setShowCheapest] = useState(true)
  const [selectedRegios, setSelectedRegios] = useState([])
  const [totalKm, setTotalKm] = useState(0)

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(null)

  const [openSnack, setOpenSnack] = useState(false)

  const [openInfo, setOpenInfo] = useState(false)
  const [infoTitle, setInfoTitle] = useState(null)
  const [infoContent, setInfoContent] = useState(null)
  const [infoWithUnderstood, setInfoWithUnderstood] = useState(false)

  const [openAlternatives, setOpenAlternatives] = useState(true)

  const [openCombineer, setOpenCombineer] = useState(false)
  const [openDeelEigenWagen, setOpenDeelEigenWagen] = useState(false)

  const handleOpenInfo = (title, content, withUnderStood) => () => {
    setInfoTitle(title)
    setInfoContent(content)
    setInfoWithUnderstood(withUnderStood)
    setOpenInfo(true)
  }
  const handleCloseInfo = (understood) => {
    setInfoTitle(null)
    setInfoContent(null)
    setOpenInfo(false)
    if (infoWithUnderstood) {
      handleSetUnderstood(moduleIdx, understood)
    }
  }

  const handleOpenAlternatives = () => {
    setOpenAlternatives(!openAlternatives)
  }

  const handleClickRegio = (selectedId) => () => {
    setSelectedRegios(
      selectedRegios.map((slr) => ({ ...slr, selected: slr.id === selectedId ? !slr.selected : slr.selected }))
    )
  }

  const onChangePriceView = (e, newValue) => {
    setPriceView(newValue)
  }
  const onChangeShowAboIncluded = () => {
    setShowAboIncluded(!showAboIncluded)
  }
  const onChangeShowCheapest = () => {
    setShowCheapest(!showCheapest)
  }

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortFunction = (a, b) =>
    showAboIncluded
      ? orderBy == null
        ? order === 'asc'
          ? a.jaarPrijsMotiefs - b.jaarPrijsMotiefs
          : b.jaarPrijsMotiefs - a.jaarPrijsMotiefs
        : order === 'asc'
        ? a.motiefs[orderBy].jaarPrijs - b.motiefs[orderBy].jaarPrijs
        : b.motiefs[orderBy].jaarPrijs - a.motiefs[orderBy].jaarPrijs
      : orderBy == null
      ? order === 'asc'
        ? a.jaarPrijsMotiefsZonderAbo - b.jaarPrijsMotiefsZonderAbo
        : b.jaarPrijsMotiefsZonderAbo - a.jaarPrijsMotiefsZonderAbo
      : order === 'asc'
      ? a.motiefs[orderBy].jaarPrijsZonderAbo - b.motiefs[orderBy].jaarPrijsZonderAbo
      : b.motiefs[orderBy].jaarPrijsZonderAbo - a.motiefs[orderBy].jaarPrijsZonderAbo

  const handleClickSelectAll = () => {
    if (selectedAboForComp.length > 0 || selectedCarForComp.length > 0) {
      handleDeselectAllForComp()
    } else {
      handleSelectAllAboForComp(
        filterResults(realResults, false)
          .filter(({ id }) => id != null)
          .map(({ id }) => id)
      )
      handleSelectCarForComp(
        filterResults(realResults, false)
          .filter(({ carId }) => carId != null)
          .map(({ carId }) => carId)[0]
      )
    }
  }

  const selectAbo = (id) => () => {
    handleSelectAboForComp(id)
  }

  const selectCar = (carId) => () => {
    handleSelectCarForComp(carId)
  }

  const onClickAddCombi = () => {
    if (selectedAboForComp.length + selectedCarForComp.length < 1) {
      setOpenSnack(true)
    } else {
      handleSetTemporaryCombi(null)
      setOpenCombineer(true)
    }
  }

  const onClickEditCombi = (uuid) => {
    handleSetTemporaryCombi(uuid)
    setOpenCombineer(true)
  }

  const handleCloseCombi = () => {
    setOpenCombineer(false)
  }

  const onClickAddDeelEigenWagen = () => {
    // handleSetTemporaryCombi(null)
    setOpenDeelEigenWagen(true)
  }

  const onClickEditDeelEigenWagen = () => {
    // handleSetTemporaryCombi(uuid)
    setOpenDeelEigenWagen(true)
  }

  const handleCloseDeelEigenWagen = () => {
    handleUpdateShareOwnCar(0)
    setOpenDeelEigenWagen(false)
  }

  const handleChangeSharedKms = (value) => {
    handleUpdateShareOwnCar(value)
  }

  const handleSaveDeelEigenWagen = () => {
    setOpenDeelEigenWagen(false)
  }

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  const handlePrevious = () => {
    navigate(`/${paths.eigenwagen[language]}`)
  }

  const handleNext = () => {
    navigate('/end')
  }

  const filterResults = (results, alternatives) =>
    results
      .filter(
        (r) =>
          !r.aanbieder ||
          (r.aanbieder &&
            selectedRegios
              .filter(({ selected }) => selected)
              .some(({ id }) => r.aanbieder.regios.some((reg) => reg.id === id)))
      )
      .filter((r) => (showAboIncluded ? r.cheapest : r.cheapestZonderAbo) || !showCheapest)
      .filter((r) =>
        alternatives ? r.motiefs.some(({ otherAuto }) => otherAuto) : !r.motiefs.some(({ otherAuto }) => otherAuto)
      )

  useEffect(() => {
    setSelectedRegios(regios.map((regio) => ({ ...regio, selected: true })))
  }, [regios])

  useEffect(() => {
    setTotalKm(
      realSelectedMotiefs?.reduce((acc, sm) => {
        acc += sm.afstand * (sm.frequentietype === 'week' ? 52 : sm.frequentietype === 'maand' ? 12 : 1) * sm.frequentie
        return acc
      }, 0)
    )
  }, [realSelectedMotiefs])

  useEffect(() => {
    if (!understood) {
      setInfoTitle(labels[modules[moduleIdx]].titel_popup_intro[language])
      setInfoContent(labels[modules[moduleIdx]].intro[language])
      setInfoWithUnderstood(true)
      setOpenInfo(true)
    }
  }, [moduleIdx, labels, language, modules, understood])

  useEffect(() => {
    handleCalculate()
  }, [realSelectedMotiefs])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  if (loading) {
    return <LinearProgress />
  }

  return (
    <Container maxWidth='lg'>
      <Snackbar
        autoHideDuration={6000}
        open={openSnack}
        onClose={handleCloseSnack}
        message={labels[modules[moduleIdx]].waarschuwing_selecteer_1[language]}
      />
      <Comment
        open={openInfo}
        title={infoTitle}
        content={infoContent}
        handleClose={handleCloseInfo}
        withUnderStood={infoWithUnderstood}
        understood={understood}
        understoodLabel={labels['start'].label_popup_begrepen[language]}
      />
      {tempCombi != null ? (
        <CombinedResultModal
          open={openCombineer}
          onClickClose={handleCloseCombi}
          language={language}
          labelsModule={labels['combineer']}
          selectedMotiefs={realSelectedMotiefs}
          // abos={abos.filter(({id}) => selectedAboForComp.includes(id))}
          abos={realResults
            .filter(({ id }) => selectedAboForComp.includes(id))
            .reduce((acc, r) => {
              r.motiefs.forEach((motief, idx) => {
                acc[idx] = acc[idx].concat([{ jaarPrijs: motief.jaarPrijs, id: r.id, naam: r.naam }])
              })
              return acc
            }, new Array(realSelectedMotiefs.length).fill([]))
            .map((col) =>
              col.sort((a, b) => {
                return a.jaarPrijs - b.jaarPrijs
              })
            )}
          ownerCars={selectedOwnerCars}
          onHandleSaveCombi={handleSaveCombi}
          onHandleChangeAbo={handleUpdateTemporaryCombi}
          combi={tempCombi}
        />
      ) : null}

      <DeelEigenWagenModal
        open={openDeelEigenWagen}
        labelsModule={labels['deeleigenwagen']}
        language={language}
        shareOwnCar={shareOwnCar}
        totalTime={totalTime}
        totalKm={totalKm}
        resultOwnerCar={resultOwnerCar}
        onClickClose={handleCloseDeelEigenWagen}
        onHandleSave={handleSaveDeelEigenWagen}
        onChangeSharedKms={handleChangeSharedKms}
      />

      <StepTitle labels={labels} modules={modules} language={language} propActiveStep={moduleIdx} />

      <div className={classes.information}>
        <Typography variant='body1'>
          {`${labels[modules[moduleIdx]].titel_totalkm[language]} (${totalKm.toLocaleString('nl-BE')} ${
            labels[modules[moduleIdx]].label_km_per_jaar[language]
          }).`}
          <span
            className={classes.info2}
            onClick={handleOpenInfo(
              labels[modules[moduleIdx]].titel_popup_intro[language],
              labels[modules[moduleIdx]].intro[language],
              true
            )}
          >
            <InfoIcon fontSize='small' />
            <Typography variant='body2' component='p'>
              {labels[modules[moduleIdx]].titel_popup_intro[language]}
            </Typography>
          </span>
        </Typography>
      </div>

      <ResultatenFilters
        moduleIdx={moduleIdx}
        labels={labels}
        modules={modules}
        language={language}
        showAboIncluded={showAboIncluded}
        showCheapest={showCheapest}
        selectedRegios={selectedRegios}
        priceView={priceView}
        onChangeShowCheapest={onChangeShowCheapest}
        onChangeShowAboIncluded={onChangeShowAboIncluded}
        handleClickRegio={handleClickRegio}
        onChangePriceView={onChangePriceView}
        handleOpenInfo={handleOpenInfo}
      />

      {realResults.length > 0 ? (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <div className={classes.view}>
            <div className={classes.wrapper}>
              <DoubleScrollbar>
                {/* matching results */}
                <Table className={classes.table}>
                  <ResultatenTableHeader
                    moduleIdx={moduleIdx}
                    labels={labels}
                    modules={modules}
                    language={language}
                    resultOwnerCar={resultOwnerCar}
                    selectedMotiefs={realSelectedMotiefs}
                    autos={autos}
                    orderBy={orderBy}
                    order={order}
                    handleUpdateMotief={handleUpdateMotief}
                    handleClickSelectAll={handleClickSelectAll}
                    createSortHandler={createSortHandler}
                  />
                  <ResultatenTableBody
                    moduleIdx={moduleIdx}
                    labels={labels}
                    modules={modules}
                    language={language}
                    results={filterResults(realResults, false).sort(sortFunction)}
                    resultOwnerCar={resultOwnerCar}
                    showAboIncluded={showAboIncluded}
                    totalKm={totalKm}
                    priceView={priceView}
                    selectedMotiefs={realSelectedMotiefs}
                    selectedAboForComp={selectedAboForComp}
                    selectedCarForComp={selectedCarForComp}
                    showSelectionColumn={true}
                    selectable={true}
                    selectAbo={selectAbo}
                    selectCar={selectCar}
                  />
                </Table>

                {/* alternative results */}
                {realResults
                  .filter((r) => (showAboIncluded ? r.cheapest : r.cheapestZonderAbo) || !showCheapest)
                  .some((r) => r.motiefs.some(({ otherAuto }) => otherAuto)) ? (
                  <Fragment>
                    <br />
                    <div className={classes.alternativeText}>
                      <strong>
                        <span>{labels[modules[moduleIdx]].titel_alternatieven[language]}</span>
                        <IconButton aria-label='expand row' size='small' onClick={handleOpenAlternatives}>
                          {openAlternatives ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </strong>
                      <ReactMarkdown children={labels[modules[moduleIdx]].tekst_alternatieven[language]} />
                    </div>

                    <Collapse in={openAlternatives} timeout='auto' unmountOnExit>
                      <Table className={classes.table} size='small'>
                        <ResultatenTableBody
                          grey
                          moduleIdx={moduleIdx}
                          labels={labels}
                          modules={modules}
                          language={language}
                          results={filterResults(realResults, true).sort(sortFunction)}
                          resultOwnerCar={resultOwnerCar}
                          totalKm={totalKm}
                          priceView={priceView}
                          selectedMotiefs={realSelectedMotiefs}
                          selectedAboForComp={selectedAboForComp}
                          selectedCarForComp={selectedCarForComp}
                          showSelectionColumn={true}
                          selectable={false}
                          selectAbo={selectAbo}
                          selectCar={selectCar}
                        />
                      </Table>
                    </Collapse>
                  </Fragment>
                ) : null}

                {realSelectedMotiefs.length > 1 ? (
                  <Fragment>
                    <br />
                    <CombiTable
                      labels={labels[modules[moduleIdx]]}
                      language={language}
                      combis={combis}
                      selectedMotiefs={realSelectedMotiefs}
                      resultOwnerCar={resultOwnerCar}
                      priceView={priceView}
                      totalKm={totalKm}
                      handleDeleteCombi={handleDeleteCombi}
                      handleEditCombi={onClickEditCombi}
                      handleAddCombi={onClickAddCombi}
                    />
                  </Fragment>
                ) : null}

                <DelenEigenWagen
                  labels={labels[modules[moduleIdx]]}
                  language={language}
                  shareOwnCar={shareOwnCar}
                  totalTime={totalTime}
                  totalKm={totalKm}
                  handleDeleteDeel={handleCloseDeelEigenWagen}
                  handleEditDeel={onClickEditDeelEigenWagen}
                  handleAddDeel={onClickAddDeelEigenWagen}
                />
                {shareOwnCar.kms != 0 && (
                  <DeelEigenWagenTable
                    labels={labels[modules[moduleIdx]]}
                    language={language}
                    selectedMotiefs={realSelectedMotiefs}
                    shareOwnCar={shareOwnCar}
                    resultOwnerCar={resultOwnerCar}
                    priceView={priceView}
                    totalKm={totalKm}
                  />
                )}
              </DoubleScrollbar>
            </div>
          </div>
        </TableContainer>
      ) : null}
      <br />

      <Actions
        actions={[
          <Button
            disableElevation={true}
            key='prev'
            onClick={handlePrevious}
            variant='outlined'
            size='large'
            color='primary'
          >
            {labels[modules[moduleIdx]].navigatie_terug[language]}
          </Button>,
          <Button
            className={classes.pdfButton}
            disableElevation={true}
            size='large'
            variant='contained'
            color='secondary'
            startIcon={<PictureAsPdfOutlinedIcon />}
            onClick={generatePdfDocument(
              language,
              labels,
              filterResults(realResults, false).sort(sortFunctionPdf),
              filterResults(realResults, true).sort(sortFunctionPdf),
              realSelectedMotiefs,
              combis,
              resultOwnerCar,
              selectedAboForComp,
              shareOwnCar
            )}
          >
            {labels['end'].navigatie_download_pdf[language]}
          </Button>,
          <Button
            disableElevation={true}
            key='next'
            onClick={handleNext}
            variant='contained'
            size='large'
            color='primary'
          >
            {labels[modules[moduleIdx]].navigatie_volgende[language]}
          </Button>,
        ]}
      />
    </Container>
  )
}

export default Resultaten
