import React, { useEffect, useReducer, Fragment, useMemo } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import 'typeface-poppins'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { theme1 } from './theme'
import Header from './components/common/header'
import Footer from './components/common/footer'
import Start from './modules/start'
import Motieven from './modules/motieven'
import EigenWagen from './modules/ownerCars'
import Resultaten from './modules/resultaten'
import End from './modules/end'
import Formules from './extras/formules'
import Privacy from './extras/privacy'
import Werkwijze from './extras/werkwijze'
import {
  getFormulas,
  getMotiefs,
  getAanbieders,
  getAbos,
  getOwnerCars,
  getAutos,
  getLabels,
  getAfschrijving,
  getBrandstofs,
  getVersie,
  getRegios,
} from './service'
import { reducer, initialState } from './reducer'
import { makeStyles } from '@material-ui/core/styles'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import Formulier from './modules/formulier'
import { paths, frenchToDutchPaths, dutchToFrenchPaths } from './extras/paths'

const axios = require('axios')
const modules = ['motieven', 'eigenwagen', 'resultaten', 'end']

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: 'calc(100vh - 260px)',
    // minHeight: 'calc(100vh - 80px)'
  },
}))

var host = window.location.hostname
if (host != 'localhost') {
  ReactGA.initialize('UA-183579479-1')
  TagManager.initialize({ gtmId: 'GTM-KHW76BL' })
  hotjar.initialize(2129557, 6)
}

function App() {
  const classes = useStyles()
  let navigate = useNavigate()

  const [state, dispatch] = useReducer(reducer, initialState)

  const restartApp = () => {
    dispatch({ type: 'RESET_ALL', payload: null })
  }

  const handleChangeLanguage = (language) => {
    if (language === 'fr') {
      sessionStorage.clear()
      restartApp()
      window.location.href = `https://calculateur.autodelen.net`
      return
    }

    if (language === 'nl') {
      sessionStorage.clear()
      restartApp()
      window.location.href = `https://bespaarmet.autodelen.net`
      return
    }

    dispatch({ type: 'CHANGE_LANGUAGE', payload: { language } })
  }

  const handleIncludeOwnCar = () => {
    dispatch({ type: 'SET_INCLUDE_OWN_CAR', payload: null })
  }

  const handleSelectMotief = (motief) => {
    dispatch({ type: 'SELECT_MOTIEF', payload: { motief } })
  }

  const handleDeSelectMotief = (idx) => {
    dispatch({ type: 'DESELECT_MOTIEF', payload: { idx } })
  }

  const handleUpdateMotief = (idx, motief) => {
    dispatch({ type: 'UPDATE_MOTIEF', payload: { idx, motief } })
  }

  const handleSelectOwnerCar = (ownerCar) => {
    dispatch({ type: 'SELECT_OWNER_CAR', payload: { ownerCar } })
  }

  const handleDeSelectOwnerCar = (idx) => {
    dispatch({ type: 'DESELECT_OWNER_CAR', payload: { idx } })
  }

  const handleUpdateOwnerCar = (ownerCar) => {
    dispatch({ type: 'UPDATE_OWNER_CAR', payload: { ownerCar } })
  }

  const handleSelectAboForComp = (selectedId) => {
    dispatch({ type: 'SELECT_ABO_FOR_COMP', payload: { selectedId } })
  }

  const handleSelectCarForComp = (selectedCarId) => {
    dispatch({ type: 'SELECT_CAR_FOR_COMP', payload: { selectedCarId } })
  }

  const handleSelectAllAboForComp = (selectedIds) => {
    dispatch({ type: 'SELECT_ALL_ABO_FOR_COMP', payload: { selectedIds } })
  }

  const handleDeselectAllForComp = () => {
    dispatch({ type: 'DESELECT_ALL_FOR_COMP', payload: null })
  }

  const handleCalculate = () => {
    dispatch({ type: 'CALCULATE', payload: null })
  }

  const handleSaveCombi = () => {
    dispatch({ type: 'SAVE_COMBI', payload: null })
  }

  const handleDeleteCombi = (uuid) => {
    dispatch({ type: 'DELETE_COMBI', payload: { uuid } })
  }

  const handleUpdateCombi = (uuid, idx, value, isAbo) => {
    dispatch({ type: 'UPDATE_COMBI', payload: { uuid, idx, value, isAbo } })
  }

  const handleSetTemporaryCombi = (uuid) => {
    dispatch({ type: 'SET_TEMPORARY_COMBI', payload: { uuid } })
  }

  const handleUpdateTemporaryCombi = (idx, value, isAbo) => {
    dispatch({
      type: 'UPDATE_TEMPORARY_COMBI',
      payload: { idx, value, isAbo },
    })
  }

  const handleUpdateShareOwnCar = (value) => {
    // console.log('handleUpdateShareOwnCar');
    dispatch({ type: 'UPDATE_SHARE_OWN_CAR', payload: { value } })
  }

  const handleSetUnderstood = (idx, understood) => {
    dispatch({ type: 'SET_UNDERSTOOD', payload: { idx, understood } })
  }

  const location = useLocation()

  useEffect(() => {
    if (host != 'localhost') {
      window.dataLayer.push({
        event: 'pageview',
        page: location.pathname + location.search,
      })
    }
  }, [location])

  useEffect(() => {
    axios
      .all([
        getFormulas(),
        getMotiefs(),
        getAanbieders(),
        getAbos(),
        getOwnerCars(),
        getAutos(),
        getLabels(),
        getAfschrijving(),
        getBrandstofs(),
        getVersie(),
        getRegios(),
      ])
      .then(
        axios.spread((resp1, resp2, resp3, resp4, resp5, resp6, resp7, resp8, resp9, resp10, resp11, resp12) => {
          dispatch({
            type: 'SET_ALL_FROM_CMS',
            payload: {
              formulas: resp1.data.data,
              motiefs: resp2.data.data,
              aanbieders: resp3.data.data,
              abos: resp4.data.data,
              ownerCars: resp5.data.data,
              autos: resp6.data.data,
              // algorithm: resp7.data.data,
              labels: resp7.data.data,
              //         .concat([
              // {"naam":"titel_deeleigenwagen","module":"resultaten","nl":"Eigen wagen delen","fr":"titel_deeleigenwagen"},
              // {"naam":"intro_deeleigenwagen","module":"resultaten","nl":"Je kan ook je eigen wagen met anderen delen. Druk op de knop hierboven om te becijferen wat je voordeel zou kunnen zijn.","fr":"tekst_deeleigenwagen"},
              // {"naam":"label_deeleigenwagen_resultaat_km","module":"resultaten","nl":"Als je je auto deelt voor","fr":"knop_popup_annuleren"},
              // {"naam":"label_deeleigenwagen_resultaat_bedrag","module":"resultaten","nl":"dan spaar je dit bedrag uit op 1 jaar tijd","fr":"knop_popup_annuleren"},
              // {"naam":"titel_popup","module":"deeleigenwagen","nl":"Berekening eigen wagen delen","fr":"titel_popup"},
              // {"naam":"intro_popup","module":"deeleigenwagen","nl":"Ter info: we gaan ervan uit dat je wagen voor maximum 70% van de tijd bezet kan zijn, en maximum 30.000 km per jaar kan rijden.","fr":"knop_popup_annuleren"},
              // {"naam":"label_popup_tijd","module":"deeleigenwagen","nl":"Huidige bezetting van je wagen","fr":"knop_popup_annuleren"},
              // {"naam":"label_popup_km","module":"deeleigenwagen","nl":"Geef het aantal kilometer op dat je je wagen wil delen","fr":"intro_popup"},
              // {"naam":"label_popup_bedrag","module":"deeleigenwagen","nl":"Dit bedrag spaar je uit op 1 jaar tijd","fr":"knop_popup_annuleren"},
              // {"naam":"knop_popup_annuleren","module":"deeleigenwagen","nl":"Toch maar niet","fr":"knop_popup_annuleren"},
              // {"naam":"knop_popup_bewaren","module":"deeleigenwagen","nl":"Dit is interessant","fr":"knop_popup_bewaren"},
              // {"naam":"titel_deeleigenwagen","module":"pdf","nl":"Eigen wagen delen","fr":"knop_popup_bewaren"},
              // {"naam":"label_deeleigenwagen_resultaat_km","module":"pdf","nl":"Als je je auto deelt voor","fr":"knop_popup_annuleren"},
              // {"naam":"label_deeleigenwagen_resultaat_bedrag","module":"pdf","nl":"dan spaar je dit bedrag uit op 1 jaar tijd","fr":"knop_popup_annuleren"},

              //         ]),
              afschrijving: resp8.data.data,
              brandstofs: resp9.data.data,
              versie: resp10.data.data,
              regios: resp11.data.data,
            },
          })
        })
      )
      .catch((error) => {
        console.log('An error occurred:', error)
      })
  }, [])

  const RouteComponent = useMemo(() => {
    return state.labels !== null ? (
      <Fragment>
        <div className={classes.content}>
          <Routes>
            <Route
              path='/'
              element={
                <Fragment>
                  <Header
                    restartApp={restartApp}
                    labels={state.labels['header']}
                    language={state.language}
                    handleChangeLanguage={handleChangeLanguage}
                    withoutLogo
                  />
                  <Start labels={state.labels['start']} language={state.language} />
                </Fragment>
              }
            />
            <Route
              path={`/${paths.motieven[state.language]}`}
              element={
                <Fragment>
                  <Header
                    restartApp={restartApp}
                    labels={state.labels['header']}
                    language={state.language}
                    handleChangeLanguage={handleChangeLanguage}
                    withoutLogo={false}
                  />
                  <Motieven
                    moduleIdx={0}
                    labels={state.labels}
                    modules={modules}
                    understood={state.understood.some((idx) => idx === 0)}
                    motiefs={state.motiefs}
                    language={state.language}
                    selectedMotiefs={state.selectedMotiefs}
                    autos={state.autos}
                    ownerCars={state.ownerCars}
                    handleSetUnderstood={handleSetUnderstood}
                    handleSelectMotief={handleSelectMotief}
                    handleDeSelectMotief={handleDeSelectMotief}
                    handleUpdateMotief={handleUpdateMotief}
                  />
                </Fragment>
              }
            />
            <Route
              path={`/${paths.eigenwagen[state.language]}`}
              element={
                <Fragment>
                  <Header
                    restartApp={restartApp}
                    labels={state.labels['header']}
                    language={state.language}
                    handleChangeLanguage={handleChangeLanguage}
                    withoutLogo={false}
                  />
                  <EigenWagen
                    moduleIdx={1}
                    labels={state.labels}
                    modules={modules}
                    understood={state.understood.some((idx) => idx === 1)}
                    language={state.language}
                    includeOwnCar={state.includeOwnCar}
                    ownerCars={state.ownerCars}
                    selectedOwnerCars={state.selectedOwnerCars}
                    selectedMotiefs={state.selectedMotiefs}
                    handleSetUnderstood={handleSetUnderstood}
                    handleCalculate={handleCalculate}
                    handleIncludeOwnCar={handleIncludeOwnCar}
                    handleSelectOwnerCar={handleSelectOwnerCar}
                    handleDeSelectOwnerCar={handleDeSelectOwnerCar}
                    handleUpdateOwnerCar={handleUpdateOwnerCar}
                  />
                </Fragment>
              }
            />
            <Route
              path='/formulier'
              element={
                <Fragment>
                  <Header
                    restartApp={restartApp}
                    labels={state.labels['header']}
                    language={state.language}
                    handleChangeLanguage={handleChangeLanguage}
                    withoutLogo={false}
                  />
                  <Formulier
                    moduleIdx={2}
                    labels={state.labels}
                    modules={modules}
                    understood={state.understood.some((idx) => idx === 2)}
                    language={state.language}
                    results={state.results}
                    selectedMotiefs={state.selectedMotiefs}
                    includeOwnCar={state.includeOwnCar}
                    selectedOwnerCars={state.selectedOwnerCars}
                    abos={state.abos}
                    autos={state.autos}
                    selectedAboForComp={state.selectedAboForComp}
                    selectedCarForComp={state.selectedCarForComp}
                    regios={state.regios}
                    combis={state.combis}
                    tempCombi={state.tempCombi}
                    shareOwnCar={state.shareOwnCar}
                    totalTime={state.totalTime}
                    handleSetUnderstood={handleSetUnderstood}
                    handleSelectAboForComp={handleSelectAboForComp}
                    handleSelectCarForComp={handleSelectCarForComp}
                    handleSelectAllAboForComp={handleSelectAllAboForComp}
                    handleDeselectAllForComp={handleDeselectAllForComp}
                    handleUpdateMotief={handleUpdateMotief}
                    handleCalculate={handleCalculate}
                    handleSaveCombi={handleSaveCombi}
                    handleUpdateCombi={handleUpdateCombi}
                    handleSetTemporaryCombi={handleSetTemporaryCombi}
                    handleUpdateTemporaryCombi={handleUpdateTemporaryCombi}
                    handleDeleteCombi={handleDeleteCombi}
                    handleUpdateShareOwnCar={handleUpdateShareOwnCar}
                  />
                </Fragment>
              }
            />
            <Route
              path={`/${paths.resultaten[state.language]}`}
              element={
                <Fragment>
                  <Header
                    restartApp={restartApp}
                    labels={state.labels['header']}
                    language={state.language}
                    handleChangeLanguage={handleChangeLanguage}
                    withoutLogo={false}
                  />
                  <Resultaten
                    moduleIdx={2}
                    labels={state.labels}
                    modules={modules}
                    understood={state.understood.some((idx) => idx === 2)}
                    language={state.language}
                    results={state.results}
                    selectedMotiefs={state.selectedMotiefs}
                    includeOwnCar={state.includeOwnCar}
                    selectedOwnerCars={state.selectedOwnerCars}
                    abos={state.abos}
                    autos={state.autos}
                    selectedAboForComp={state.selectedAboForComp}
                    selectedCarForComp={state.selectedCarForComp}
                    regios={state.regios}
                    combis={state.combis}
                    tempCombi={state.tempCombi}
                    shareOwnCar={state.shareOwnCar}
                    totalTime={state.totalTime}
                    handleSetUnderstood={handleSetUnderstood}
                    handleSelectAboForComp={handleSelectAboForComp}
                    handleSelectCarForComp={handleSelectCarForComp}
                    handleSelectAllAboForComp={handleSelectAllAboForComp}
                    handleDeselectAllForComp={handleDeselectAllForComp}
                    handleUpdateMotief={handleUpdateMotief}
                    handleCalculate={handleCalculate}
                    handleSaveCombi={handleSaveCombi}
                    handleUpdateCombi={handleUpdateCombi}
                    handleSetTemporaryCombi={handleSetTemporaryCombi}
                    handleUpdateTemporaryCombi={handleUpdateTemporaryCombi}
                    handleDeleteCombi={handleDeleteCombi}
                    handleUpdateShareOwnCar={handleUpdateShareOwnCar}
                  />
                </Fragment>
              }
            />

            <Route
              path={`/${paths.resultaten[state.language]}/:id`}
              element={
                <Fragment>
                  <Header
                    restartApp={restartApp}
                    labels={state.labels['header']}
                    language={state.language}
                    handleChangeLanguage={handleChangeLanguage}
                    withoutLogo={false}
                  />
                  <Resultaten
                    moduleIdx={2}
                    labels={state.labels}
                    modules={modules}
                    understood={state.understood.some((idx) => idx === 2)}
                    language={state.language}
                    results={state.results}
                    selectedMotiefs={state.selectedMotiefs}
                    includeOwnCar={state.includeOwnCar}
                    selectedOwnerCars={state.selectedOwnerCars}
                    abos={state.abos}
                    autos={state.autos}
                    selectedAboForComp={state.selectedAboForComp}
                    selectedCarForComp={state.selectedCarForComp}
                    regios={state.regios}
                    combis={state.combis}
                    tempCombi={state.tempCombi}
                    shareOwnCar={state.shareOwnCar}
                    totalTime={state.totalTime}
                    handleSetUnderstood={handleSetUnderstood}
                    handleSelectAboForComp={handleSelectAboForComp}
                    handleSelectCarForComp={handleSelectCarForComp}
                    handleSelectAllAboForComp={handleSelectAllAboForComp}
                    handleDeselectAllForComp={handleDeselectAllForComp}
                    handleUpdateMotief={handleUpdateMotief}
                    handleCalculate={handleCalculate}
                    handleSaveCombi={handleSaveCombi}
                    handleUpdateCombi={handleUpdateCombi}
                    handleSetTemporaryCombi={handleSetTemporaryCombi}
                    handleUpdateTemporaryCombi={handleUpdateTemporaryCombi}
                    handleDeleteCombi={handleDeleteCombi}
                    handleUpdateShareOwnCar={handleUpdateShareOwnCar}
                  />
                </Fragment>
              }
            />

            <Route
              path='/end'
              element={
                <Fragment>
                  <Header
                    restartApp={restartApp}
                    labels={state.labels['header']}
                    language={state.language}
                    handleChangeLanguage={handleChangeLanguage}
                    withoutLogo={false}
                  />
                  <End
                    moduleIdx={3}
                    labels={state.labels}
                    modules={modules}
                    language={state.language}
                    results={state.results.filter(
                      (r) =>
                        (r.carId != null && state.selectedCarForComp.includes(r.carId)) ||
                        (r.id != null && state.selectedAboForComp.includes(r.id))
                    )}
                    combis={state.combis}
                    selectedMotiefs={state.selectedMotiefs}
                  />
                </Fragment>
              }
            />

            <Route
              path='/formules'
              element={
                <Fragment>
                  <Header
                    restartApp={restartApp}
                    labels={state.labels['header']}
                    language={state.language}
                    handleChangeLanguage={handleChangeLanguage}
                    withoutLogo={false}
                  />
                  <Formules aanbieders={state.aanbieders} abos={state.abos} />
                </Fragment>
              }
            />
            {/* <Route path="/info" element={
          <Algorithm algorithm={state.algorithm} />
        }
        
        />
        */}
            <Route
              path='/werkwijze'
              element={
                <Fragment>
                  <Header
                    restartApp={restartApp}
                    labels={state.labels['header']}
                    language={state.language}
                    handleChangeLanguage={handleChangeLanguage}
                    withoutLogo={false}
                  />
                  <Werkwijze text={state.labels['footer'].werkwijze[state.language]} />
                </Fragment>
              }
            />
            <Route
              path='/privacy'
              element={
                <Fragment>
                  <Header
                    restartApp={restartApp}
                    labels={state.labels['header']}
                    language={state.language}
                    handleChangeLanguage={handleChangeLanguage}
                    withoutLogo={false}
                  />
                  <Privacy text={state.labels['footer'].privacybeleid[state.language]} />
                </Fragment>
              }
            />
          </Routes>
        </div>
        <Footer versiedatum={state.versiedatum} labels={state.labels['footer']} language={state.language} />
      </Fragment>
    ) : null
  }, [state])

  return (
    <ThemeProvider theme={theme1}>
      <CssBaseline />
      {RouteComponent}
    </ThemeProvider>
  )
}

export default App
